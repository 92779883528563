import * as React from "react";

function SvgComponent(props) {
    return (
        <svg
            x="0px"
            y="0px"
            width="100%"
            height="100%"
            viewBox="0 0 438.536 438.535"
            xmlSpace="preserve"
            role="img"
            aria-labelledby="linkedInIconTitle linkedInIconDesc"
            {...props}
        >
            <title id="linkedInIconTitle">LinkedIn</title>
            <description id="linkedInIconDesc">LinkedIn Icon</description>
            <path d="M5.424 145.895H99.64V428.827H5.424z" />
            <path d="M408.842 171.739c-19.791-21.604-45.967-32.408-78.512-32.408-11.991 0-22.891 1.475-32.695 4.427-9.801 2.95-18.079 7.089-24.838 12.419-6.755 5.33-12.135 10.278-16.129 14.844-3.798 4.337-7.512 9.389-11.136 15.104v-40.232h-93.935l.288 13.706c.193 9.139.288 37.307.288 84.508 0 47.205-.19 108.777-.572 184.722h93.931V270.942c0-9.705 1.041-17.412 3.139-23.127 4-9.712 10.037-17.843 18.131-24.407 8.093-6.572 18.13-9.855 30.125-9.855 16.364 0 28.407 5.662 36.117 16.987 7.707 11.324 11.561 26.98 11.561 46.966V428.82h93.931V266.664c-.007-41.688-9.897-73.328-29.694-94.925zM53.103 9.708c-15.796 0-28.595 4.619-38.4 13.848C4.899 32.787 0 44.441 0 58.529 0 72.42 4.758 84.034 14.275 93.358c9.514 9.325 22.078 13.99 37.685 13.99h.571c15.99 0 28.887-4.661 38.688-13.99 9.801-9.324 14.606-20.934 14.417-34.829-.19-14.087-5.047-25.742-14.561-34.973C81.562 14.323 68.9 9.708 53.103 9.708z" />
        </svg>
    );
}

export default SvgComponent;
