import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Avatar } from "../components/avatar/"
import Icon from '../components/Icons/'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div itemScope itemType="http://schema.org/Person">
      <Link to="/">
        <Avatar
          itemProp="image"
          srcSet="https://www.gravatar.com/avatar/413d1fd50a63886b02e7adaa75cc3fcc?s=250 2x"
          src="https://www.gravatar.com/avatar/413d1fd50a63886b02e7adaa75cc3fcc?s=125"
          width="125"
          height="125"
          alt="James Wakefield"
        />
      </Link>
      <h1 className="heading_secondary" itemProp="jobTitle">
        <em>Front End Engineer</em>
      </h1>
      <h2 className="heading_primary" itemProp="name">
        James Wakefield
      </h2>
      <p itemProp="description">
        A friendly and professional Front End engineer with over 10 years
        experience based in <span>Amsterdam</span>, NL.
      </p>
      <p>
        Currently: Team Lead Front End at{" "}
        <a itemProp="worksFor" href="https://www.debijenkorf.nl">
          de Bijenkorf
        </a>
        .
      </p>
      <ul className="icon-list">
        <li className="icon-list__item">
          <a href="https://dribbble.com/james-wakefield">
            <Icon name="dribbble" />
          </a>
        </li>
        <li className="icon-list__item">
          <a href="https://github.com/bijenkorf-james-wakefield">
            <Icon name="github" />
          </a>
        </li>
        <li className="icon-list__item">
          <a href="https://www.linkedin.com/in/jameswakefield/">
            <Icon name="linkedin" />
          </a>
        </li>
      </ul>
    </div>
  </Layout>
)

export default IndexPage
