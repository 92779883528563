import React from 'react';

import Dribble from './Dribbble';
import LinkedIn from './LinkedIn';
import Github from './Github';

const Icon = ({ name, className = "svg-icon", ...rest }) => {
    switch (name) {
        case "github":
            return <Github className="svg-icon" {...rest} />
        case "linkedin":
            return <LinkedIn className="svg-icon" {...rest} />
        case "dribbble":
            return <Dribble className="svg-icon" {...rest} />
        default:
            return null
    }
}

export default Icon;