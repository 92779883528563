import * as React from "react";

function SvgComponent(props) {
    return (
        <svg
            x="0px"
            y="0px"
            width="100%"
            height="100%"
            viewBox="0 0 475.084 475.084"
            xmlSpace="preserve"
            role="img"
            aria-labelledby="githubIconTitle githubIconDesc"
            {...props}
        >
            <title id="githubIconTitle">Github</title>
            <description id="githubIconDesc">Github Icon</description>
            <path d="M436.244 146.752c5.14-15.422 7.713-31.409 7.713-47.967 0-22.08-4.859-42.828-14.564-62.242-20.362 0-38.349 3.715-53.961 11.136-15.604 7.423-33.4 18.938-53.379 34.545-25.122-6.09-51.777-9.135-79.941-9.135-30.837 0-60.245 3.333-88.223 9.994-20.364-15.99-38.351-27.74-53.959-35.26-15.608-7.52-33.689-11.279-54.247-11.279-9.707 19.414-14.56 40.163-14.56 62.242 0 16.751 2.568 32.93 7.708 48.535C12.942 177.587 0 215.272 0 260.383c0 39.595 5.898 71.092 17.701 94.507 6.283 12.367 14.465 23.312 24.554 32.832 10.085 9.514 21.601 17.228 34.545 23.13 12.946 5.896 25.981 10.801 39.116 14.699 13.134 3.9 27.646 6.758 43.54 8.559 15.893 1.816 29.93 3.004 42.111 3.579 12.181.564 25.693.853 40.544.853 17.508 0 33.396-.432 47.678-1.283 14.277-.855 30.594-2.953 48.964-6.276 18.367-3.333 34.547-7.857 48.54-13.565 13.99-5.708 27.412-13.895 40.259-24.551 12.847-10.663 22.884-23.318 30.121-37.976 11.604-23.603 17.412-55.107 17.412-94.507-.007-45.302-12.95-83.178-38.841-113.632zm-34.249 207.703c-6.092 12.471-13.802 22.265-23.127 29.41-9.329 7.139-20.938 12.847-34.831 17.135-13.9 4.281-27.217 7.087-39.971 8.415-12.758 1.334-26.933 1.998-42.545 1.998h-47.966c-15.607 0-29.79-.664-42.541-1.998-12.752-1.328-26.075-4.134-39.971-8.415-13.891-4.288-25.5-9.996-34.829-17.135-9.329-7.146-17.037-16.939-23.128-29.41-6.09-12.471-9.136-27.076-9.136-43.824 0-22.847 6.567-42.264 19.702-58.245 13.134-15.99 30.929-23.982 53.387-23.982 8.188 0 26.746 1.997 55.677 5.995 13.513 2.093 28.456 3.14 44.823 3.14 16.372 0 31.313-1.044 44.824-3.14 29.317-3.999 47.869-5.995 55.678-5.995 22.457 0 40.252 7.996 53.386 23.982 13.135 15.988 19.698 35.398 19.698 58.245 0 16.751-3.046 31.364-9.13 43.824z" />
            <path d="M166.875 265.52c-5.806-6.475-12.703-9.712-20.699-9.712-7.998 0-14.896 3.241-20.701 9.712-5.802 6.468-9.897 13.703-12.275 21.689-2.383 8.002-3.571 15.804-3.571 23.422 0 7.61 1.191 15.413 3.571 23.414 2.375 7.991 6.468 15.222 12.275 21.689 5.808 6.475 12.703 9.713 20.701 9.713 7.996 0 14.896-3.244 20.699-9.713 5.804-6.468 9.897-13.698 12.275-21.689 2.38-8.001 3.571-15.804 3.571-23.414 0-7.611-1.188-15.42-3.571-23.422-2.379-7.983-6.468-15.215-12.275-21.689zM349.601 265.52c-5.804-6.475-12.703-9.712-20.697-9.712-7.991 0-14.894 3.241-20.701 9.712-5.804 6.468-9.896 13.703-12.271 21.689-2.385 8.002-3.576 15.804-3.576 23.422 0 7.61 1.191 15.413 3.576 23.414 2.375 7.991 6.468 15.222 12.271 21.689 5.808 6.475 12.71 9.713 20.701 9.713 7.994 0 14.894-3.244 20.697-9.713 5.801-6.468 9.896-13.698 12.278-21.689 2.379-8.001 3.569-15.804 3.569-23.414 0-7.611-1.19-15.42-3.569-23.422-2.381-7.983-6.477-15.215-12.278-21.689z" />
        </svg>
    );
}

export default SvgComponent;
